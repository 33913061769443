<template>
  <div class="">
    <div class="max-w-7xl mx-auto px-10 md:px-16 lg:px-16 flex flex-col items-center">
      <p class="text-sm text-gray-500">
        © 2003 – {{ new Date().getFullYear() }} Ezgi Çınarlı – All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
